.title-404 {
    font-family: "Source Sans Pro" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 35px !important;
    line-height: 72px !important;

    color: #1180b6 !important;

    text-shadow: 0px 2px 8px rgba(17, 111, 147, 0.1) !important;
}
.desc-404 {
    font-family: "Source Sans Pro" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;

    color: #1180b6 !important;
}
