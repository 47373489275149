@font-face {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    src: local(""), url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-regular.woff2) format("woff2"),
        url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-regular.woff) format("woff");
    font-display: swap
}

@font-face {
    font-family: Source Sans Pro;
    font-style: italic;
    font-weight: 400;
    src: local(""), url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-italic.woff2) format("woff2"),
        url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-italic.woff) format("woff");
    font-display: swap
}

@font-face {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    src: local(""), url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-600.woff2) format("woff2"),
        url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-600.woff) format("woff");
    font-display: swap
}

@font-face {
    font-family: Source Sans Pro;
    font-style: italic;
    font-weight: 600;
    src: local(""), url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-600italic.woff2) format("woff2"),
        url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-600italic.woff) format("woff");
    font-display: swap
}

@font-face {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 700;
    src: local(""), url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-700.woff2) format("woff2"),
        url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-700.woff) format("woff");
    font-display: swap
}

@font-face {
    font-family: Source Sans Pro;
    font-style: italic;
    font-weight: 700;
    src: local(""), url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-700italic.woff2) format("woff2"),
        url(../../../public/assets/font/source-sans-pro-v18-latin_cyrillic-700italic.woff) format("woff");
    font-display: swap
}