.btn-sign.MuiButtonBase-root {
    background: #EC1F5F !important;
    border-radius: 4px !important;
    width: 100%;
    height: 54px !important;
    max-height: 54px !important;
    font-style: normal !important;
    // font-weight: 700 !important;
    font-size: 16px !important;
    box-shadow: none;
    &.Mui-disabled{
        color: #fff !important;
    }
}

.MuiButtonBase-root {
    &.Mui-disabled{
        color: inherit !important;
    }
}