.divider-line.MuiDivider-root {
    border-color: #a8a5a5;
    border-width: 2px !important;
    height: 2px !important;
    color: #545454;
    font-size: 18px !important;
    font-weight: 700;
    display: flex;
    align-items: center;
}
